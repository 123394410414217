import React, { Component } from 'react';
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { BrowserView, MobileView, } from 'react-device-detect';
import ScrollToTopNoDelay from '../Features/ScrollToTop';
import '../Home.css';
import '../Leistungen.css';
import '../AboutUs.css';
import './impressum.css';

const iconPerson = new L.Icon({
    iconUrl: require('../Modules/Images/Pin.png'),
    iconRetinaUrl: require('../Modules/Images/Pin.png'),
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(40, 40),
    className: 'leaflet-div-icon'
});
export { iconPerson };


export default function Impressum(props) {
    const contentStyle = {
        color: props.theme.palette.text.primary,
        backgroundColor: props.theme.palette.background.default,
        transition: props.theme.transitions.create(),
    };

    return (
        <div className="Datenschutz" style={contentStyle}>
            <ScrollToTopNoDelay />
            <BrowserView>
                <h2 className="headline-small-center" style={contentStyle}><center>IMPRESSUM</center></h2>
            </BrowserView>
            <MobileView>
                <h2 className="headline-small-center-mobile" style={contentStyle}><center>IMPRESSUM</center></h2>
            </MobileView>

            <table>
                <tr>
                    <td>
                        <h2 >Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
                        <p>Gebrüder Dieing GbR | DIEING Waldpflege<br />
                        Geschäftsführende Gesellschafter: Dietmar Dieing & Christoph Dieing  <br />
                            Am Galgenb&uuml;hl 11<br />
                            D - 88316 Isny im Allgäu</p>

                        <h2>Kontakt</h2>
                        <p>Tel.: +49 (0) 7562 912712<br />
                        Fax: +49 (0) 7562 9145074<br />
                        E-Mail: info@dieings@dieing.de</p>

                        <h2>Umsatzsteuer-ID</h2>
                        <p>Umsatzsteuer-ID: DE271772607</p><br/>
                            St.-Nr.: 91070/45955

                        <h2>Redaktionell verantwortlich</h2>
                        <p>Christoph Dieing</p><br/>
                        Hauptsitz der Gesellschaft: Isny i. A. <br/>

                        <h2>EU-Streitschlichtung</h2>
                        <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer" style={contentStyle}>https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

                        <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
                        <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

                        <p>Quelle: <a href="https://www.e-recht24.de" style={contentStyle}>e-recht24.de</a></p>
                        <br></br>
                        <p>Made by <a href="https://www.d3fx.tv/" style={contentStyle}>D3FX</a></p>
                    </td>
                    <td>


                    </td>
                </tr>
            </table>
            <br></br>
            <MapContainer
                center={[47.70665, 10.05504]}
                zoom="30"
                scrollWheelZoom={false}
                className="markercluster-map"
            >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <link rel="stylesheet" href="https://unpkg.com/leaflet@1.0.1/dist/leaflet.css" />
                <Marker position={[47.70662, 10.05514]} icon={iconPerson}>
                    <Popup>
                        Wood.in.Vision <br /> Am Galgenbühl 11 <br />88316 Isny im Allgäu
                    </Popup>
                </Marker>
            </MapContainer>
            <br></br>
            <br></br>
        </div>
    )
}