import React from 'react'
import './Home.css';
import Background from './Videos/Background.mp4'
import { BrowserView, MobileView, } from 'react-device-detect';
import ScrollToTopNoDelay from './Features/ScrollToTop';
import { Button } from "@react-email/components";
import "react-image-gallery/styles/css/image-gallery.css";
import LogoSlider from './Modules/LogoSlider/Slider';


import Image1 from "./Modules/Images/Sliders/Home/Digitalisierter Forstwirt.jpg";
import Image2 from "./Modules/Images/Sliders/Home/Forstwirt Dieing Waldpflege.jpg";
import Image3 from "./Modules/Images/Sliders/Home/Imagebild Radharvester Dieing Waldpflege.jpg";
import Image4 from "./Modules/Images/Sliders/Home/Luftbild Forwarder Dieing Waldpflege.jpg";
import Image5 from "./Modules/Images/Sliders/Home/Neuson Raupenharvester bei der Arbeit.jpg";
import Image6 from "./Modules/Images/Sliders/Home/Poltervermessung durch den Förster.jpg";
import Image7 from "./Modules/Images/Sliders/Home/Ponsse Ergo.jpg";
import Image8 from "./Modules/Images/Sliders/Home/Raupenharvester.jpg";
import Image9 from "./Modules/Images/Sliders/Home/Sägemehl Schwertschnitt Ponsse Ergo Dieing Waldpflege.jpg";
import Image10 from "./Modules/Images/Sliders/Home/Start Drohnenflug.jpg";

const images = [
  {
    original: Image1,
  },
  {
    original: Image2,
  },
  {
    original: Image3,
  },
  {
    original: Image4,
  },
  {
    original: Image5,
  },
  {
    original: Image6,
  },
  {
    original: Image7,
  },
  {
    original: Image8,
  },
  {
    original: Image9,
  },
  {
    original: Image10,
  },
];


export default function Home(props) {
  const contentStyle = {
    color: props.theme.palette.text.primary,
    backgroundColor: props.theme.palette.background.default,
    transition: props.theme.transitions.create(),
  };

  return (
    <div>
      <ScrollToTopNoDelay />
      <BrowserView>
        <section className="banner1">
          <div className="videooverlay"><img src={require("./Modules/Images/Logo.png")} alt="" className="overlay-logo"></img></div>
          <div className="container">
            <video className='videoTag' autoPlay loop muted width={"100%"}>
              <source src={Background} type='video/mp4' />
            </video>
          </div>
        </section>

        <section>
          <h1 className="headline-small-center" style={contentStyle}><center>WIR PFLEGEN IHREN WALD - SEIT 1958</center></h1>
        </section>
        <section>
          <h2 className="contentbox" style={contentStyle}><center>DIEING Waldpflege führt für Sie alle Arbeiten in Ihrem Wald aus. Bis hin zur kompletten Übernahme der Waldpflege<br /> über Waldpflegeverträge. </center></h2>
        </section>
        <section className='spaceinbetween' />
        <center>
          <div style={{ paddingLeft: "20%", paddingRight: "20%" }}>
            {/*<ImageGallery items={images} showBullets={true} showThumbnails={false} lazyLoad={true} showPlayButton={false} autoPlay={true} showFullscreenButton={false} />*/}
          </div>
        </center>


        <section>
          <h2 className="headline-small-center" style={contentStyle}><center>UNSERE KUNDEN</center> </h2>

          <font className="contentbox" style={contentStyle}>
            <center>
              Unsere kompetenten Förster, Försterinnen und Forstwirte und Forstwirtinnen stehen Ihnen gerne beratend zur Seite,<br></br> um gemeinsam ein gutes Konzept zur Bewirtschaftung Ihres Waldes zu entwickeln.

              Sprechen Sie uns an wir sind für Sie da.
            </center>

          </font>
          <section className='spaceinbetween' />
          <center>
            <div style={{ paddingLeft: "20%", paddingRight: "20%" }}>
              <LogoSlider themeMode={props.themeMode} />

              <Button class="Contact"
                href="mailto:info@wood-in-vision.com?subject=Anfrage Dieing Waldpflege. "
                style={{ color: "#ffffff", padding: "10px 20px" }}
              >
                Anfrage
              </Button>
            </div>
          </center>
        </section>

        <section className='spaceinbetween' />


        <section className='spaceinbetween' />
      </BrowserView>

      <MobileView>
        <section className="banner1">
          <div className="videooverlay"><img src={require("./Modules/Images/Logo.png")} alt="" className="overlay-logo-mobile"></img></div>
          <div className="container">
            <video className='videoTag' autoPlay loop muted width={"100%"}>
              <source src={Background} type='video/mp4' />
            </video>
          </div>
        </section>

        <section>
          <h1 className="headline-small-center-mobile" style={contentStyle}><center>WIR PFLEGEN IHREN WALD - seit 1958</center></h1>
        </section>
        <section>
          <h2 className="contentbox-mobile" style={contentStyle}><center>DIEING Waldpflege führt für Sie alle Arbeiten in Ihrem Wald aus. Bis hin zur kompletten Übernahme der Waldpflege. </center></h2>
        </section>
        <section className='spaceinbetween' />
        <center>
          {/*<ImageGallery items={images} showBullets={true} showThumbnails={false} lazyLoad={true} showPlayButton={false} autoPlay={true} showFullscreenButton={false} />*/}
        </center>


        <section>
          <h2 className="headline-small-center-mobile" style={contentStyle}><center>UNSERE KUNDEN </center></h2>

          <font className="contentbox" style={contentStyle}>
            <center>
              Unsere kompetenten Förster, Försterinnen, Forstwirte und Forstwirtinnen stehen Ihnen gerne beratend zur Seite, <br></br>um gemeinsam ein gutes Konzept zur Bewirtschaftung Ihres Waldes zu entwickeln.

              Sprechen Sie uns an wir sind für Sie da.
            </center>
          </font>
          <section className='spaceinbetween' />
          <center>
            <div style={{ paddingLeft: "20%", paddingRight: "20%" }}>
              <LogoSlider themeMode={props.themeMode} />
            </div>
          </center>
        </section>

        <section className='spaceinbetween' />
        <center>
        <Button class="Contact"
                href="mailto:info@wood-in-vision.com?subject=Anfrage Dieing Waldpflege. "
                style={{ color: "#ffffff", padding: "10px 20px" }}
              >
                Anfrage
              </Button>
        <section className='spaceinbetween' />
        </center>
      </MobileView>



    </div>

  )
}