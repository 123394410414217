import React from "react";
import Slider from "react-slick";
import { Box } from "@chakra-ui/react";

import fbw from "../Images/Logos/Logo1.jpg";
import bsf from "../Images/Logos/Logo2.jpg";
import stl from "../Images/Logos/Logo3.jpg";
import bod from "../Images/Logos/Logo4.jpg";
import wld from "../Images/Logos/Logo5.png";
import blr from "../Images/Logos/Logo6.png";


import { BrowserView, MobileView, } from 'react-device-detect';


export default function LogoSlider(props) {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1, autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    CssEase: "linear",
  };

  return (
    <div>
      <Box m="80">
        <Box
          sx={{
            ".slick-dots": {
              transform: "translateY(1em)"
            },
            ".slick-dots li button": {
              _before: {
                transition: "0.2s",
                content: "''",
                borderRadius: "100%",
                background: "cyan.500"
              }
            },
            ".slick-arrow": {
              backgroundColor: "cyan.500",
              color: "white",
              w: "30px",
              h: "50px",
              transition: "0.2s",
              _hover: {
                backgroundColor: "cyan.500",
                color: "white"
              },
              _focus: {
                backgroundColor: "cyan.500",
                color: "white"
              },
              _before: {
                transition: "0.2s"
              }
            },
            ".slick-prev": {
              left: "-40px",
              _before: {
                content: '"◀"'
              }
            },
            ".slick-next": {
              right: "-40px",
              _before: {
                content: '"▶"'
              }
            }
          }}
        >

          <BrowserView>
            <Slider {...settings}>
              <div>
                <h3><a href="https://www.fundraiso.com/de/organisations/furstlich-schwarzenbergsche-familienstiftung" target="_blank" rel="noopener noreferrer"><img src={fbw} width="300" height="auto" style={props.themeMode == "light" ? { filter: "invert(1)" } : undefined} /></a></h3>
              </div>
              <div>
                <h3><a href="https://www.facebook.com/p/Sattler-F-F-Holz-KG-100057409939717/" target="_blank" rel="noopener noreferrer"><img src={bsf} width="300" height="auto" style={props.themeMode == "light" ? { filter: "invert(1)" } : undefined} /></a></h3>
              </div>
              <div>
                <h3><a href="https://forstservice-wuertenberger.de/" target="_blank" rel="noopener noreferrer"><img src={stl} width="300" height="auto" style={props.themeMode == "light" ? { filter: "invert(1)" } : undefined} /></a></h3>
              </div>
              <div>
                <h3><a href="https://reichardt-online.de/" target="_blank" rel="noopener noreferrer"><img src={bod} width="300" height="auto" style={props.themeMode == "light" ? { filter: "invert(1)" } : undefined} /></a></h3>
              </div>

            </Slider>
            <section>
              <h1 className="headline-small-center" ><center>UNSERE PARTNER</center></h1>
            </section>
            <section className='spaceinbetween' />
            <Slider {...settings}>
              <div>
                <h3><a href="" target="_blank" rel="noopener noreferrer"><img src={wld} width="300" height="auto" style={props.themeMode == "light" ? { filter: "invert(1)" } : undefined} /></a></h3>
              </div>
              <div>
                <h3><a href="" target="_blank" rel="noopener noreferrer"><img src={blr} width="300" height="auto" style={props.themeMode == "light" ? { filter: "invert(1)" } : undefined} /></a></h3>
              </div>


            </Slider>
          </BrowserView>

          <MobileView>
            <center>
              <div>
                <h3><a href="https://www.fundraiso.com/de/organisations/furstlich-schwarzenbergsche-familienstiftung" target="_blank" rel="noopener noreferrer"><img src={fbw} width="180" height="auto" style={props.themeMode == "light" ? { filter: "invert(1)" } : undefined} /></a></h3>
              </div>
              <div>
                <h3><a href="https://www.facebook.com/p/Sattler-F-F-Holz-KG-100057409939717/" target="_blank" rel="noopener noreferrer"><img src={bsf} width="180" height="auto" style={props.themeMode == "light" ? { filter: "invert(1)" } : undefined} /></a></h3>
              </div>
              <div>
                <h3><a href="https://forstservice-wuertenberger.de/" target="_blank" rel="noopener noreferrer"><img src={stl} width="180" height="auto" style={props.themeMode == "light" ? { filter: "invert(1)" } : undefined} /></a></h3>
              </div>
              <div>
                <h3><a href="https://reichardt-online.de/" target="_blank" rel="noopener noreferrer"><img src={bod} width="180" height="auto" style={props.themeMode == "light" ? { filter: "invert(1)" } : undefined} /></a></h3>
              </div>
            </center>

          </MobileView>

        </Box>
      </Box>
    </div>
  );
}