import React, { useState } from 'react';
import './Leistungen.css';
import './Home.css';
import { BrowserView, MobileView, } from 'react-device-detect';
import ImageGallery from "react-image-gallery";
import ScrollToTopNoDelay from './Features/ScrollToTop';
import LogoSlider from './Modules/LogoSlider/Slider';

import Image1 from "./Modules/Images/Sliders/Kulturpflege/Vermessung von Pflanzflächen für Förderung von W.I.V.jpg";

import Image4 from "./Modules/Images/Sliders/Schadholzüberwachung/Drohnenmonitoring digitale Gassen.jpg";
import Image6 from "./Modules/Images/Sliders/Schadholzüberwachung/Imagebild Drohne und Schadholzmarkierung.jpg";
import Image8 from "./Modules/Images/Sliders/Schadholzüberwachung/Imagebild Forstwirt Dieing Waldpflege.jpg";
import Image9 from "./Modules/Images/Sliders/Schadholzüberwachung/Schadholzmonitoring W.I.V.jpg";
import Image10 from "./Modules/Images/Sliders/Schadholzüberwachung/Start Drohnenflug.jpg";
import Image12 from "./Modules/Images/Sliders/Schadholzüberwachung/Wärmebild Drohne.jpg";

import Image13 from "./Modules/Images/Sliders/Holzernte/1.4 Holzernte motormanuell.jpg";
import Image17 from "./Modules/Images/Sliders/Holzernte/Gasse 2.0.jpg";
import Image18 from "./Modules/Images/Sliders/Holzernte/Imagebild Forwarder Dieing Waldpflege.jpg";
import Image19 from "./Modules/Images/Sliders/Holzernte/Imagebild Ponsse Dieing Waldpflege.jpg";
import Image24 from "./Modules/Images/Sliders/Holzernte/Raupenharvester mit Forwarder.jpg";

import Image26 from "./Modules/Images/Sliders/Wldpflege/Flächenbearbeitung W.I.V.jpg";
import Image27 from "./Modules/Images/Sliders/Wldpflege/Flächenbestimmung durch Revierleiter.jpg";
import Image28 from "./Modules/Images/Sliders/Wldpflege/Flurstückmarkierung.jpg";
import Image29 from "./Modules/Images/Sliders/Wldpflege/Holzpolteraufnahme via Bild.jpg";
import Image30 from "./Modules/Images/Sliders/Wldpflege/Kulturbegründung WOOD.IN.VISION.jpg";
import Image31 from "./Modules/Images/Sliders/Wldpflege/Poltervermessung durch den Förster.jpg";
import Image33 from "./Modules/Images/Sliders/Wldpflege/Vermessung,Flurstückserfassung.jpg";


const Kulturpflege = [
    {
        original: Image1,
    },
];

const Schadholz = [
    {
        original: Image10,
    },
    {
        original: Image6,
    },
    {
        original: Image4,
    },
    {
        original: Image12,
    },
    {
        original: Image9,
    },
    {
        original: Image8,
    },
];


const Holzernte = [
    {
        original: Image24,
    },
    {
        original: Image19,
    },
    {
        original: Image18,
    },
    {
        original: Image17,
    },
    {
        original: Image13,
    },
];

const Holzverkauf = [
    {
        original: Image29,
    },
    {
        original: Image31,
    },

];

const Waldpflege = [
    {
       
        original: Image27,
    },
    {
        original: Image26,
    },
    {
        original: Image28,
    },
    {
        original: Image30,
    },
    {
        original: Image33,
    },
];




export default function Leistungen(props) {
    const contentStyle = {
        color: props.theme.palette.text.primary,
        backgroundColor: props.theme.palette.background.default,
        transition: props.theme.transitions.create(),
    };


    return (
        <div style={contentStyle}>
            <ScrollToTopNoDelay />
            <MobileView>
                <section>
                    <h2 className="headline-small-center-mobile" style={contentStyle}><center>UNSERE LEISTUNGEN</center></h2>
                    <h2 className="headline-small-mobile" style={contentStyle}>Nachhaltige Flächenvorbereitung, Pflanzung & Kulturpflege</h2>
                    <font className="secondrow-mobile" style={contentStyle}>
                        Damit Wald auch kommenden Generationen zur Verfügung steht, bieten wir auf ihre Bedürfnisse abgestimmte Kulturbgründungsmaßnahmen an.
                        Wir sind mit unseren Mitarbeitern mit einer einzigartigen digitalen Planung über unser Waldmanagement Tool WOOD.IN.VISION bereits bei
                        der Flächenbearbeitung präzise unterwegs. Ob Mulchen, Pflanzmengen oder Freischneiden gepflanzter Flächen. Bei uns ist alles digital
                        erfasst, wir vergessen keine Maßnahme unserer Kunden. Neueste Technik ausgebildete Forstwirte und Förster garantieren Ihnen eine
                        exzellente Pflege Ihres Waldes, bereits von Kindesbeinen an.
                    </font>
                    <section className='spaceinbetween' />
                    <center>
                        <div style={{ paddingLeft: "20%", paddingRight: "20%" }}>
                            <ImageGallery items={Kulturpflege} showThumbnails={false} lazyLoad={true} showPlayButton={false} showFullscreenButton={false} />

                        </div>
                    </center>

                </section>

                <section className='spaceinbetween' />

                <section>
                    <h2 className="headline-small-mobile" style={contentStyle}>Hochgenaue Schadholzüberwachung</h2>

                    <font className="secondrow-mobile" style={contentStyle}>
                        Wir überfliegen mit RTK-Drohnen die Wälder unserer Kunden um Sturm und Schadholz direkt aus der Luft zu erkennen und digital (per GPS-Punkt)
                        verortet unsere Einsätze zu planen. Selbstverständlich sind wir auch im Wald unterwegs um jederzeit den Gesundheitszustand sowie die
                        Naturverjüngung zu kontrollieren.

                    </font>
                    <section className='spaceinbetween' />
                    <center>
                        <div style={{ paddingLeft: "20%", paddingRight: "20%" }}>
                            <ImageGallery items={Schadholz} showBullets={true} showThumbnails={false} lazyLoad={true} showPlayButton={false} autoPlay={true} showFullscreenButton={false} />
                        </div>

                    </center>
                </section>

                <section className='spaceinbetween' />

                <section>
                    <h2 className="headline-small-mobile" style={contentStyle}>Maschinelle & manuelle Holzernte</h2>

                    <font className="secondrow-mobile" style={contentStyle}>
                        Je nach Gelände und Umfang bieten wir bodenschonende Harvestereinsätze oder auch händische Hiebe an. In Zusammenarbeit mit lokalen Rückeunternehmen
                        wird das eingeschlagene Holz abfahrbereit zur Verfügung gestellt. Wir setzen ausschließlich auf Maschinen von Weltmarktführern, um einen möglichst
                        störungsfreien Betrieb an unseren Einsatzorten zu gewährleisten. Höchste Qualität und immer optimal gewartetes Equipment. Ein maschinenunabhängiger,
                        voll digitalisierter Auftragsfluss ermöglicht uns zusätzlich die Aufträge zielgenau zu planen und „Just-in-Time“ umzusetzen. Volle Information
                        jederzeit für unsere Kunden.

                        Unser Leistungsportfolio in der Holzernte:
                        <br /><br />
                        Raupenharvester
                        <br /><br />
                        8-Rad Harvester
                        <br /><br />
                        8-Rad Rückezug

                    </font>
                    <section className='spaceinbetween' />
                    <center>
                        <div style={{ paddingLeft: "20%", paddingRight: "20%" }}>
                            <ImageGallery items={Holzernte} showBullets={true} showThumbnails={false} lazyLoad={true} showPlayButton={false} autoPlay={true} showFullscreenButton={false} />
                        </div>
                    </center>
                </section>

                <section className='spaceinbetween' />

                <section>
                    <h2 className="headline-small-mobile" style={contentStyle}>Holzverkauf</h2>

                    <font className="secondrow-mobile" style={contentStyle}>
                        Wir übernehmen bei Bedarf auch die Holzvermarktung. Mit unseren Kontakten in die Holzindustrie haben wir immer den optimalen Kunden für Ihr Holz.

                    </font>
                    <section className='spaceinbetween' />
                    <center>
                        <div style={{ paddingLeft: "20%", paddingRight: "20%" }}>
                            <ImageGallery items={Holzverkauf} showBullets={true} showThumbnails={false} lazyLoad={true} showPlayButton={false} autoPlay={true} showFullscreenButton={false} />
                        </div>
                    </center>
                </section>


                <section>
                    <h2 className="headline-small-mobile" style={contentStyle}>Vollumfängliche Waldpflegeverträge</h2>

                    <font className="secondrow-mobile" style={contentStyle}>
                        Im Rahmen eines Waldpflegevertrages übernimmt die Dieing Waldpflege die Bewirtschaftung der Fläche in enger Absprache mit Ihnen als Waldbesitzer. Wir setzen bei
                        der Dieing Waldpflege hauptsächlich eigenes Personal, eigene Maschinen und bei Bedarf unsere zertifizierten Partner ein. Wir führen alle Tätigkeiten von der Pflanzung
                        bis zur Holzvermarktung, inklusive der Abrechnung, für Sie aus. Je nach dem was und wieviel Sie in Ihrem Wald selbst erledigen wollen. Alle erfassten Daten werden von
                        uns digital für Sie in unserer „Mein Wald“ Plattform hinterlegt. Sie haben jederzeit die Möglichkeit Ihren Wald über ein aktuelles Luftbild zu sehen und das digitale
                        Revierbuch zeigt Ihnen den Lebenslauf samt den anstehenden Arbeiten. <br />-- Den Lebenslauf Ihres Waldes immer im Blick --
                    </font>
                    <section className='spaceinbetween' />
                    <center>
                        <div style={{ paddingLeft: "20%", paddingRight: "20%" }}>
                            <ImageGallery items={Waldpflege} showBullets={true} showThumbnails={false} lazyLoad={true} showPlayButton={false} autoPlay={true} showFullscreenButton={false} />
                        </div>
                    </center>
                </section>

                <section className='spaceinbetween' />

                <section>
                    <h2 className="headline-small-mobile" style={contentStyle}>UNSERE KUNDEN</h2>

                    <font className="secondrow-mobile" style={contentStyle}>

                        Unsere kompetenten Förster, Försterinnen und Forstwirte stehen Ihnen gerne beratend zur Seite, um gemeinsam ein gutes Konzept zur Bewirtschaftung Ihres Waldes zu entwickeln.

                        Sprechen Sie uns an wir sind für Sie da.

                    </font>
                    <section className='spaceinbetween' />
                    <center>
                        <div style={{ paddingLeft: "20%", paddingRight: "20%" }}>
                            <LogoSlider />
                        </div>
                    </center>
                </section>

                <section className='spaceinbetween' />

            </MobileView>


            <BrowserView>
                <section>
                    <h2 className="headline-small-center" style={contentStyle}><center>UNSERE LEISTUNGEN</center></h2>
                    <h2 className="headline-small" style={contentStyle}>Nachhaltige Flächenvorbereitung, Pflanzung und Kulturpflege</h2>
                    <font className="secondrow" style={contentStyle}>
                        Damit Wald auch kommenden Generationen zur Verfügung steht, bieten wir auf ihre Bedürfnisse abgestimmte Kulturbgründungsmaßnahmen an.
                        Wir sind mit unseren Mitarbeitern mit einer einzigartigen digitalen Planung über unser Waldmanagement Tool WOOD.IN.VISION bereits bei
                        der Flächenbearbeitung präzise unterwegs. Ob Mulchen, Pflanzmengen oder Freischneiden gepflanzter Flächen. Bei uns ist alles digital
                        erfasst, wir vergessen keine Maßnahme unserer Kunden. Neueste Technik ausgebildete Forstwirte und Förster garantieren Ihnen eine
                        exzellente Pflege Ihres Waldes, bereits von Kindesbeinen an.
                    </font>
                    <section className='spaceinbetween' />
                    <center>
                        <div style={{ paddingLeft: "20%", paddingRight: "20%" }}>
                            <ImageGallery items={Kulturpflege} showThumbnails={false} lazyLoad={true} showPlayButton={false} showFullscreenButton={false} />
                        </div>
                    </center>
                </section>

                <section className='spaceinbetween' />

                <section>
                    <h2 className="headline-small" style={contentStyle}>Hochgenaue Schadholzüberwachung</h2>

                    <font className="secondrow" style={contentStyle}>
                        Wir überfliegen mit RTK-Drohnen die Wälder unserer Kunden um Sturm und Schadholz direkt aus der Luft zu erkennen und digital (per GPS-Punkt)
                        verortet unsere Einsätze zu planen. Selbstverständlich sind wir auch im Wald unterwegs um jederzeit den Gesundheitszustand sowie die
                        Naturverjüngung zu kontrollieren.

                    </font>
                    <section className='spaceinbetween' />
                    <center>
                        <div style={{ paddingLeft: "20%", paddingRight: "20%" }}>
                            <ImageGallery items={Schadholz} showBullets={true} showThumbnails={false} lazyLoad={true} showPlayButton={false} autoPlay={true} showFullscreenButton={false} />
                        </div>
                    </center>
                </section>

                <section className='spaceinbetween' />

                <section>
                    <h2 className="headline-small" style={contentStyle}>Maschinelle & manuelle Holzernte</h2>

                    <font className="secondrow" style={contentStyle}>
                        Je nach Gelände und Umfang bieten wir bodenschonende Harvestereinsätze oder auch händische Hiebe an. In Zusammenarbeit mit lokalen Rückeunternehmen
                        wird das eingeschlagene Holz abfahrbereit zur Verfügung gestellt. Wir setzen ausschließlich auf Maschinen von Weltmarktführern, um einen möglichst
                        störungsfreien Betrieb an unseren Einsatzorten zu gewährleisten. Höchste Qualität und immer optimal gewartetes Equipment. Ein maschinenunabhängiger,
                        voll digitalisierter Auftragsfluss ermöglicht uns zusätzlich die Aufträge zielgenau zu planen und „Just-in-Time“ umzusetzen. Volle Information
                        jederzeit für unsere Kunden.

                        Unser Leistungsportfolio in der Holzernte:
                        <br /><br />
                        <ul>
                            <li>
                                Raupenharvester
                            </li>
                            <li>
                                Raupenrückezug
                            </li>
                            <li>
                                8-Rad Harvester
                            </li>
                            <li>
                                8-Rad Rückezug
                            </li>
                        </ul>
                    </font>
                    <section className='spaceinbetween' />
                    <center>
                        <div style={{ paddingLeft: "20%", paddingRight: "20%" }}>
                            <ImageGallery items={Holzernte} showBullets={true} showThumbnails={false} lazyLoad={true} showPlayButton={false} autoPlay={true} showFullscreenButton={false} />
                        </div>
                    </center>
                </section>

                <section className='spaceinbetween' />

                <section>
                    <h2 className="headline-small" style={contentStyle}>Holzverkauf</h2>

                    <font className="secondrow" style={contentStyle}>
                        Wir übernehmen bei Bedarf auch die Holzvermarktung. Mit unseren Kontakten in die Holzindustrie haben wir immer den optimalen Kunden für Ihr Holz.

                    </font>
                    <section className='spaceinbetween' />
                    <center>
                        <div style={{ paddingLeft: "20%", paddingRight: "20%" }}>
                            <ImageGallery items={Holzverkauf} showBullets={true} showThumbnails={false} lazyLoad={true} showPlayButton={false} autoPlay={true} showFullscreenButton={false} />
                        </div>
                    </center>
                    <section className='spaceinbetween' />
                </section>

                <section>
                    <h2 className="headline-small" style={contentStyle}>Vollumfängliche Waldpflegeverträge</h2>

                    <font className="secondrow" style={contentStyle}>
                        Im Rahmen eines Waldpflegevertrages übernimmt die Dieing Waldpflege die Bewirtschaftung der Fläche in enger Absprache mit Ihnen als Waldbesitzer. Wir setzen bei
                        der Dieing Waldpflege hauptsächlich eigenes Personal, eigene Maschinen und bei Bedarf unsere zertifizierten Partner ein. Wir führen alle Tätigkeiten von der Pflanzung
                        bis zur Holzvermarktung, inklusive der Abrechnung, für Sie aus. Je nach dem was und wieviel Sie in Ihrem Wald selbst erledigen wollen. Alle erfassten Daten werden von
                        uns digital für Sie in unserer „Mein Wald“ Plattform hinterlegt. Sie haben jederzeit die Möglichkeit Ihren Wald über ein aktuelles Luftbild zu sehen und das digitale
                        Revierbuch zeigt Ihnen den Lebenslauf samt den anstehenden Arbeiten. <br />-- Den Lebenslauf Ihres Waldes immer im Blick --
                    </font>
                    <section className='spaceinbetween' />
                    <center>
                        <div style={{ paddingLeft: "20%", paddingRight: "20%" }}>
                            <ImageGallery items={Waldpflege} showBullets={true} showThumbnails={false} lazyLoad={true} showPlayButton={false} autoPlay={true} showFullscreenButton={false} />
                        </div>
                    </center>
                </section>

                <section className='spaceinbetween' />
                <section className='spaceinbetween' />
            </BrowserView>
        </div >
    );
}
